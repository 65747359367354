$(function() {
    var $btnLoadmore = $('.btn-loadmore');

    if(!$btnLoadmore.length) return
    
    $btnLoadmore.on('click', function(e) {
        e.preventDefault();
        
        var button = $(this);
        var data = {
            'action': 'loadmore',
            'query': loadmore_params.posts,
            'page': loadmore_params.current_page
        }
        
        $.ajax({
            url: loadmore_params.ajaxurl,
            type: 'POST',
            data: data,
            beforeSend: function(xhr) {
                button.text('Carregando...');
            },
            success: function(data) {
                if(data) {

                    button.text('Carregar mais');
                    
                    $(data).appendTo('.row.loadmore');

                    loadmore_params.current_page++;

                    if(loadmore_params.current_page == loadmore_params.max_page)
						button.parent().remove();
                } else {
                    button.parent().remove();
                }
            }
        });
    });
});