document.addEventListener("DOMContentLoaded", function () {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy, source.lazy"));

    if ("IntersectionObserver" in window) {
        var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var lazyImage = entry.target;
                    if (lazyImage.tagName === "IMG") {
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.onload = function () {
                            lazyImage.classList.remove("lazy");
                        }
                    } else if (lazyImage.tagName === "SOURCE") {
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        var parentPicture = lazyImage.parentNode;
                        var imgTag = parentPicture.querySelector('img.lazy');
                        if (imgTag) {
                            imgTag.src = imgTag.dataset.src;
                            imgTag.onload = function () {
                                lazyImage.classList.remove("lazy");
                                imgTag.classList.remove("lazy");
                            }
                        }
                    }
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Fallback para navegadores que não suportam IntersectionObserver
        lazyImages.forEach(function (lazyImage) {
            if (lazyImage.tagName === "IMG") {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.onload = function () {
                    lazyImage.classList.remove("lazy");
                }
            } else if (lazyImage.tagName === "SOURCE") {
                lazyImage.srcset = lazyImage.dataset.srcset;
                var parentPicture = lazyImage.parentNode;
                var imgTag = parentPicture.querySelector('img.lazy');
                if (imgTag) {
                    imgTag.src = imgTag.dataset.src;
                    imgTag.onload = function () {
                        lazyImage.classList.remove("lazy");
                        imgTag.classList.remove("lazy");
                    }
                }
            }
        });
    }
});
