/* Behavior - behaviors/slide.js */

$(function () {
    var $slide = $('.box-slide .owl-carousel');

    if(!$slide.length) return

    $slide.owlCarousel({
        items: 1,
        autoplay: true,
        loop: false,
        lazyLoad: true,
        navContainer: $('.box-slide .slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 8000
    });

    var $slideNavs = $('.box-slide .slide-navs-inner.disabled');
    
    if(!$slideNavs.length) return
    $('.box-slide .slide-navs').addClass('disabled');
});

$(function () {
    var $slide = $('.authorized .owl-carousel');

    if(!$slide.length) return

    $slide.owlCarousel({
        items: 6,
        autoplay: false,
        loop: false,
        lazyLoad: true,
        margin: 30,
        navContainer: $('.authorized .slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 3000,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            415:{
                items:2,
                nav:true
            },
            575:{
                items:3,
                nav:true
            },
            767:{
                items:4,
                nav:true
            },
            991:{
                items:5,
                nav:true
            },
            1170:{
                items:6,
                nav:true
            },
        }
    });

    var $slideNavs = $('.authorized .slide-navs-inner.disabled');
    
    if(!$slideNavs.length) return
    $('.authorized .slide-navs').addClass('disabled');
});

$(function () {
    var $slide = $('.specialized .owl-carousel');

    if(!$slide.length) return

    $slide.owlCarousel({
        items: 5,
        autoplay: false,
        loop: false,
        lazyLoad: true,
        margin: 30,
        navContainer: $('.specialized .slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 3000,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            415:{
                items:2,
                nav:true
            },
            575:{
                items:3,
                nav:true
            },
            767:{
                items:4,
                nav:true
            },
            991:{
                items:5,
                nav:true
            },
            1170:{
                items:6,
                nav:true
            },
        }
    });

    var $slideNavs = $('.specialized .slide-navs-inner.disabled');
    
    if(!$slideNavs.length) return
    $('.specialized .slide-navs').addClass('disabled');
});